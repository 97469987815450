import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import useConfirm from '../../../hooks/useConfirm';

// Component to display a confirm dialog
export default function ConfirmDialog() {
  const { onConfirm, onCancel, confirmState } = useConfirm();

  return (
    <Dialog open={confirmState.show} maxWidth='sm' fullWidth>
      <DialogTitle>{confirmState.title}</DialogTitle>
      <DialogContent>{confirmState.text}</DialogContent>
      <DialogActions>
        <Button color='error' onClick={onCancel}>
          Cancel
        </Button>
        <Button color='success' onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
