import Router from "./Router";
import CustomizedSnackbars from "./components/Snackbar";
import { CartProvider } from "./contexts/cart/CartContext";
import { DataProvider } from "./contexts/data/DataContext";
import { DialogProvider } from "./contexts/dialog/DialogContext";
import { SnackbarProvider } from "./contexts/snackbar/SnackbarContext";
import { SwitchThemeProvider } from "./contexts/theme/SwitchTheme";
import { AuthProvider } from "./hooks/useAuth";
import ErrorDialog from './layouts/main/dialogs/Error';
import ThemeProvider from './theme/Provider';

function App() {
  return (
    <SwitchThemeProvider>
      <ThemeProvider>
        <DialogProvider>
          <DataProvider>
            <CartProvider>
              <AuthProvider>
                <SnackbarProvider>
                  <Router />
                  <ErrorDialog />
                  <CustomizedSnackbars />
                </SnackbarProvider>
              </AuthProvider>
            </CartProvider>
          </DataProvider>
        </DialogProvider>
      </ThemeProvider>
    </SwitchThemeProvider>
  );
}

export default App;
