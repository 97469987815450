import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../services/Api';

// Ignore lint react-hooks/exhaustive-deps for this file
/* eslint-disable react-hooks/exhaustive-deps */
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { api } = useApi();
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    const response = await api.post('/assign', { ...data, assigned_at: new Date().toISOString() });
    if (response.status !== 200) {
      return false;
    }
    const token = response.data.token;
    window.localStorage.setItem('access_token', token);
    return true;
  };

  // call this function to sign out logged in user
  const logout = () => {
    window.localStorage.removeItem('access_token');
    navigate('/');
  };

  const value = useMemo(
    () => ({
      login,
      logout,
    }),
    [],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
