export const reducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      let newState = { ...state };
      if (state.hasOwnProperty(action.key)) {
        // state[action.key].amount++;
        newState = { ...state, [action.key]: { ...state[action.key], amount: state[action.key].amount + 1 } };
      }
      else {
        newState = { ...state, [action.key]: { product: action.value, id: action.value.id, amount: 1 } };
      }
      window.localStorage.setItem('cart', JSON.stringify(newState));
      return newState;
    }
    case 'sub': {
      if (state.hasOwnProperty(action.key)) {
        let newState = { ...state };
        if (state[action.key].amount > 1) {
          newState = { ...state, [action.key]: { ...state[action.key], amount: state[action.key].amount - 1 } };
        }
        else {
          newState = { ...state };
          delete newState[action.key];
          // newState = newState;
        }
        window.localStorage.setItem('cart', JSON.stringify(newState));
        return newState;
      }
      else {
        window.localStorage.setItem('cart', JSON.stringify(state));
        return state;
      }
    }
    case 'clear': {
      window.localStorage.setItem('cart', JSON.stringify({}));
      return {};
    }
    default: {
      window.localStorage.setItem('cart', JSON.stringify(state));
      return state;
    }
  }
};

export let initialState = JSON.parse(window.localStorage.getItem("cart")) ?? {};
