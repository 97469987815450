import { useContext } from 'react';
import { SnackbarContext } from '../contexts/snackbar/SnackbarContext';

export default function useSnackbar() {
  const [snackbarState, snackbarDispatch] = useContext(SnackbarContext);

  const close = () => {
    snackbarDispatch({ type: 'close' });
  };

  const info = (text) => {
    snackbarDispatch({ type: 'info', text });
  };
  const error = (text) => {
    snackbarDispatch({ type: 'error', text });
  };
  const success = (text) => {
    snackbarDispatch({ type: 'success', text });
  };
  const warning = (text) => {
    snackbarDispatch({ type: 'warning', text });
  };

  return { error, success, info, warning, snackbarState, close };
}
