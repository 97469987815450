import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { initialState, reducer } from './Reducer';

export const ConfirmContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const ConfirmProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ConfirmContext.Provider value={[state, dispatch]}>{children}</ConfirmContext.Provider>;
};

ConfirmProvider.propTypes = {
  children: PropTypes.node,
};
