import PropTypes from 'prop-types';
// material
import { AppBar, Box, Button, Stack, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/Iconify';
//

// ----------------------------------------------------------------------

// The height of the appbar on mobile
const APPBAR_MOBILE = 64;
// The height of the appbar on desktop
const APPBAR_DESKTOP = 64;

// Style for the root element
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  // [theme.breakpoints.up('lg')]: {
  //   width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  // },
}));

// Style for the toolbar
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------
// props
DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

// Component to show the navbar
export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate();
  return (
    <RootStyle>
      <ToolbarStyle>
        {
          // Show button if path is not /app
          window.location.pathname !== "/app" &&
          <Button onClick={() => navigate(-1)} color="warning" startIcon={<Iconify icon="eva:arrow-back-fill" />}>Go Back</Button>
        }
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover />
          <AccountPopover /> */}
          <Button onClick={() => navigate("/balance")} color="info" variant="contained">Show balance</Button>
          {/* <SwitchThemeIconButton /> */}
          {/* <IconButton><Iconify icon="eva:settings-fill" /></IconButton> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
