import { Box, Container, Grid } from "@mui/material";
import { useContext } from "react";
import CategoryItem from "../components/CategoryItem";
import { DataContext } from "../contexts/data/DataContext";

export default function StartPage() {
    const [state] = useContext(DataContext);

    if (!state.categories || state.categories.length === 0) {
        return null;
    }

    return <Container>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <CategoryItem category={{ title: 'All Products' }} />
                </Grid>
                {
                    state.categories.map((category, index) => {
                        return <Grid item xs={12} md={4} key={index}>
                            <CategoryItem category={category} />
                        </Grid>
                    }
                    )
                }
            </Grid>
        </Box>
    </Container>
}