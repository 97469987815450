// Ignore lint react-hooks/exhaustive-deps for this file
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ProductItem from "../components/ProductItem";
import { DataContext } from "../contexts/data/DataContext";

export default function ProductsPage() {
    const [state] = useContext(DataContext);
    const [categories, setCategories] = useState([]);
    // Get category id from url params
    // eslint-disable-next-line no-unused-vars
    const [params, setParams] = useSearchParams();
    const categoryId = params.get("category");


    useEffect(() => {
        try {
            if (!categoryId) {
                setCategories(state.categories);
            } else {
                setCategories(state.categories.filter(category => category.id === parseInt(categoryId)));
            }
        } catch (error) {
            console.log(error);
        }
    }, [state])


    if (!state || !state.categories || state.categories.length === 0 || !categories) {
        return null;
    }
    return <Container>
        {
            categories.map((category, index) => {
                return <Box key={index}>
                    <Stack spacing={2}>
                        <Typography variant="h3">{category.title}</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                {
                                    state.products.filter(product => JSON.parse(product.categories).includes(category.title)).map((product, index) => {
                                        return <Grid item xs={12} sm={4} md={3} key={index}>
                                            <ProductItem product={product} />
                                        </Grid>
                                    }
                                    )
                                }
                            </Grid>
                        </Box>
                    </Stack>
                </Box>
            }
            )
        }
    </Container>
}