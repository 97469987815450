// Ignore lint react-hooks/exhaustive-deps for this file
/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import { ConfirmProvider } from "../../contexts/confirm/ConfirmContext";
import { DataContext } from "../../contexts/data/DataContext";
import BottomBar from "./BottomBar";
import DashboardNavbar from "./Navbar";
import ConfirmDialog from "./dialogs/Confirm";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

// Create the root style
const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
});

// Create the main style
const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    // minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function MainLayout({ children }) {
    // eslint-disable-next-line no-unused-vars
    const [_, dispatch] = useContext(DataContext);
    const ws = useRef(null);
    const retryCountRef = useRef(0);
    const retryIntervalRef = useRef(1000); // 1 second retry interval
    const retryTimeoutRef = useRef(null);

    useEffect(() => {
        connect();

        return () => {
            if (retryTimeoutRef.current) {
                clearTimeout(retryTimeoutRef.current);
            }
            try {
                if (ws.current) {
                    ws.current.close(1000);
                }
            } catch (error) {
                ws.current = null;
            }
        }
    }, []);


    // Connect to the websocket server
    const connect = () => {
        // Return if a connection is already established
        if (ws.current) {
            return;
        }

        // Create the connection
        let websocket = new WebSocket(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_WEBSOCKET : "ws://localhost:8080/api/device/ws");

        // Set the event handlers
        websocket.onopen = () => {
            ws.current = websocket;
            retryCountRef.current = 0;
            retryIntervalRef.current = 1000;

            // Send initial message to the server
            websocket.send(JSON.stringify({
                event: 'connecting',
                token: window.localStorage.getItem("access_token"),
                timestamp: new Date(Date.now())
            }))
        };

        // Handle incoming messages
        websocket.onmessage = (e) => {
            try {
                dispatch({
                    type: 'set',
                    value: JSON.parse(e.data)
                })
            } catch (error) {
                // Do nothing
            }
        };

        // Handle connection closed
        websocket.onclose = (e) => {
            ws.current = null;

            // Check if close was done manually or connection was lost
            if (e.code === 1000) {
                return; // Connection closed normally
            }
            // Retry connection
            retryConnection();
        };

        // Handle connection errors
        websocket.onerror = (e) => {
            ws.current = null;

            // Retry connection
            retryConnection();
        };

        // ws.current = websocket;
    };

    const retryConnection = () => {
        if (ws.current) {
            return; // Connection already established, no need to retry
        }

        retryTimeoutRef.current = setTimeout(() => {
            connect();
        }, retryIntervalRef.current);

        retryCountRef.current += 1;
        retryIntervalRef.current *= 2; // Exponential backoff

        // Add additional checks or conditions to stop retrying after a certain number of attempts, if desired

        return () => clearTimeout(retryTimeoutRef.current);
    };


    // useEffect(() => {
    //     console.log("Websocket changed")
    //     const retryTimeout = setTimeout(() => {
    //         if (!ws.current) {
    //             connect();
    //         }
    //     }, retryIntervalRef.current);

    //     return () => clearTimeout(retryTimeout);
    // }, [ws.current]);



    return <RootStyle>
        <ConfirmProvider>
            <DashboardNavbar />
            <MainStyle>
                <Outlet>{children}</Outlet>
            </MainStyle>
            <ConfirmDialog />
            <BottomBar />
        </ConfirmProvider>
    </RootStyle>
}