import axios from 'axios';
import useDialog from '../hooks/useDialog';

// Hooks for api using axios
export default function useApi() {
  const { error } = useDialog();

  // method for displaying a 404 error
  const notFoundError = async (err) => {
    const defaultNotFoundError = {
      title: 'The requested resource was not found',
      text: 'The requested resource was not found',
      status: '404',
    };
    const data = await err.response.data;
    if (data.title && data.text && data.status) {
      await error(data.title, data.text, data.status);
      return;
    }
    await error(defaultNotFoundError.title, defaultNotFoundError.text, defaultNotFoundError.status);
    return;
  };

  // method for displaying a 401 error
  const unAuthorizedError = async (err) => {
    const defaultUnAuthorizedError = {
      title: 'Adgang nægtet',
      text: 'Du har ikke adgang til denne ressource',
      status: '401',
    };
    const data = await err.response.data;
    if (data.title && data.text && data.status) {
      await error(data.title, data.text, data.status);
      return;
    }
    await error(
      defaultUnAuthorizedError.title,
      defaultUnAuthorizedError.text,
      defaultUnAuthorizedError.status,
    );
    return;
  };

  // Create the axios instance
  const api = axios.create({
    // Ignore eslint error, this is a variable that is set in the .env file
    // eslint-disable-next-line no-undef
    baseURL: process.env.NODE_ENV === "development" ? "http://localhost:8080/api/device/" : process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-type': 'application/json',
      token: localStorage.getItem('access_token') ?? "",
    },
  });

  // Interceptor for the response
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      if (!err.response) {
        await error(
          'Internal Server Error',
          'An unexpected error occured',
          500,
        );
        return Promise.reject(err);
      }
      switch (err.response.status) {
        case 404:
          await notFoundError(err);
          return Promise.reject(err);
        case 400:
          await error('Bad Request', 'Bad Request', 400);
          return Promise.reject(err);
        case 401:
          await unAuthorizedError(err);
          returnToLoginUnlesPathIsLogin();
          return Promise.reject(err);
        case 500:
          await error(
            'Internal Server Error',
            'An unexpected error occured',
            500,
          );
          return Promise.reject(err);
        default:
          await error(
            'Unexpected Error',
            'An unexpected error occured',
            500,
          );
          return Promise.reject(err);
      }
    },
  );

  return { api };
}

// Method for redirecting to login page if not already there
const returnToLoginUnlesPathIsLogin = () => {
  if (window.location.pathname === '/sign-on') {
    return;
  }
  localStorage.removeItem('access_token');
  window.location.href = '/sign-on';
};
