export const reducer = (state, action) => {
  switch (action.type) {
    case 'error': {
      return { error: true, text: action.text, success: false, info: false, warning: false };
    }
    case 'info': {
      return { info: true, text: action.text, success: false, error: false, warning: false };
    }
    case 'success': {
      return { success: true, text: action.text, error: false, info: false, warning: false };
    }
    case 'warning': {
      return { warning: true, text: action.text, error: false, info: false, success: false };
    }
    case 'close': {
      return { error: false, info: false, warning: false, success: false };
    }
    default: {
      return false;
    }
  }
};

export const initialState = {
  error: false,
  info: false,
  warning: false,
  succes: false,
  text: '',
};
