export const reducer = (state, action) => {
  switch (action.type) {
    case 'error': {
      return { error: true, errorObject: action.errorObject };
    }
    case 'info': {
      return { info: true, infoObject: action.infoObject };
    }
    case 'confirm': {
      return { confirm: true, confirmObject: action.confirmObject };
    }
    case 'close': {
      return { error: false, info: false, confirm: false };
    }
    default: {
      return false;
    }
  }
};

export const initialState = {
  error: false,
  errorObject: {},
  info: false,
  infoObject: {},
  confirm: false,
  confirmObject: {},
};
