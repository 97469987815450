import { Avatar, Grid, IconButton } from "@mui/material";
import React from "react";


const NUMBERS = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    0
]


export default function Numpad({ onClick }) {
    const numberItem = (num) => {
        return <IconButton onClick={() => onClick(num.toString())}>
            <Avatar sx={{
                width: 80,
                height: 80,
                bgcolor: 'rgba(0, 0, 0, .2)',
                color: 'white',
                fontSize: 38
            }}>{num}</Avatar>
        </IconButton>
    }
    return <Grid container maxWidth={300} justifyContent={"center"} >
        {
            NUMBERS.map((num, index) => (
                <Grid key={index} item xs={4}>
                    {numberItem(num)}
                </Grid>
            ))
        }
    </Grid>
}