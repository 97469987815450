export const reducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      window.localStorage.setItem('theme', action.value);
      return { ...state, mode: action.value };
    }
    default: {
      return { ...state, show: false };
    }
  }
};

export const initialState = {
  mode: window.localStorage.getItem('theme') ?? 'dark',
};
