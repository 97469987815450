import { styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ConfirmProvider } from "../../contexts/confirm/ConfirmContext";
import DashboardNavbar from "./Navbar";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

// Create the root style
const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
});

// Create the main style
const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'hidden',
    maxHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: APP_BAR_MOBILE + 24,
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export default function SimpleLayout({ children }) {
    return <RootStyle>
        <ConfirmProvider>
            <DashboardNavbar />
            <MainStyle>
                <Outlet>
                    {children}
                </Outlet>
            </MainStyle>
        </ConfirmProvider>
    </RootStyle>
}