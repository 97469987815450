import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Container, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/Iconify";
import Numpad from "../components/numpad";
import useDialog from "../hooks/useDialog";

export default function ShowBalancePage() {
    const [number, setNumber] = useState(null);
    const theme = useTheme();
    const { error } = useDialog();
    const navigate = useNavigate();

    const backspaceClick = () => {
        if (number !== null && number.length > 0) {
            if (number.length === 1) {
                setNumber(null)
            } else {
                setNumber(number.slice(0, -1))
            }
        }
    }

    const handleClick = (val) => {
        if (number === null) {
            setNumber(val)
        } else {
            if (number.length >= 3) {
                return
            }
            setNumber(number + val)
        }
    }

    const handleConfirm = async () => {
        if (number === null) {
            error("Invalid number", "Please enter a valid number", "1234")
            return
        }
        navigate(`/balance/${number}`)
    }

    return <Container>
        <Stack>
            <Typography variant="h2">Show balance</Typography>
            <Stack alignItems="center">
                <Card>
                    <CardContent>
                        <Stack alignItems="center" spacing={2}>
                            <Grid container sx={{
                                width: 300,
                                borderBottom: 1,
                                mb: 2
                            }}
                                direction={"row"}
                                alignItems={"center"}
                            >
                                <Grid item xs={2}></Grid>
                                <Grid item xs={8} sx={{ textAlign: 'center', fontSize: 38 }}>
                                    {
                                        number !== null ? number : <Typography sx={{ fontSize: 'inherit' }} color={theme.palette.text.disabled}>000</Typography>
                                    }
                                </Grid>
                                <Grid item xs={2}><IconButton onClick={backspaceClick}><Iconify icon="eva:backspace-fill" /></IconButton></Grid>
                            </Grid>
                            <Numpad onClick={handleClick} />
                            <LoadingButton onClick={handleConfirm} size="large" color="success" variant="contained">Show</LoadingButton>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Stack>
    </Container>

}