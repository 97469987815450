import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { initialState, reducer } from './Reducer';

export const DialogContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const DialogProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <DialogContext.Provider value={[state, dispatch]}>{children}</DialogContext.Provider>;
};

// proptypes
DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
