import { Avatar, Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { CartContext } from "../contexts/cart/CartContext";
import useConfirm from "../hooks/useConfirm";
import Iconify from "./Iconify";

export default function CheckoutOrder() {
    const [cart, dispatch] = useContext(CartContext);
    const theme = useTheme();
    const { confirm } = useConfirm();

    // Display text if cart is empty
    if (Object.keys(cart).length === 0) {
        return <h1>Cart is empty</h1>
    }


    const addToCart = (product) => {
        dispatch({ type: 'add', key: product.id, value: product });
    }

    const removeFromCart = async (product, amount) => {
        if (amount === 1) {
            const result = await confirm("Are you sure you want to remove this item from the cart?", "");
            if (result) {
                dispatch({ type: 'sub', key: product.id });
            }
            return;
        }
        dispatch({ type: 'sub', key: product.id });
    }



    const totalPrice = Object.values(cart).reduce((acc, cur) => acc + cur.amount * cur.product.price, 0);

    return <Stack px={2}>
        <Box>
            <Stack spacing={2}>
                {
                    Object.values(cart).map((item, index) => {
                        const { product, amount } = item;
                        return <Stack key={index} direction="row" alignItems="center" justifyContent="flex-start">
                            <Avatar sx={{ bgcolor: 'rgba(0,0,0,1)' }}>
                                <img src={product.image} alt={product.name} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                            </Avatar>
                            <Stack alignItems="flex-start">
                                <Typography variant="h6">{`${amount}x ${product.name}`}</Typography>
                                <Stack direction="row" alignItems="center" justifyContent="space-evenly" >
                                    <IconButton sx={{ width: 48, height: 48, color: theme.palette.text.primary }} onClick={() => removeFromCart(product, amount)}>
                                        <Iconify sx={{ width: '100%', height: '100%' }} icon="eva:minus-circle-fill" />
                                    </IconButton>
                                    <Typography variant="h4">{cart.hasOwnProperty(product.id) ? cart[product.id].amount : 0}</Typography>
                                    <IconButton sx={{ width: 48, height: 48, color: theme.palette.text.primary }} onClick={() => addToCart(product)}>
                                        <Iconify sx={{ width: '100%', height: '100%' }} icon="eva:plus-circle-fill" />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Box sx={{ flexGrow: 1 }} />
                            <Typography variant="h6">{`${product.price * amount}${product.unit}`}</Typography>
                        </Stack>
                    }
                    )
                }
            </Stack>
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h3">Total Price</Typography>
            <Typography variant="h3">{`${totalPrice}€`}</Typography>
        </Stack>
    </Stack >
}