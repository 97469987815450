import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { fDate } from "../../services/FormatTime";
import Iconify from "../Iconify";

export default function ProductsRow({ order }) {
    const [open, setOpen] = useState(false);

    const handleExpandClick = () => {
        setOpen(!open);
    }
    return <Fragment>
        <TableRow key={order.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>{`#${order.id}`}</TableCell>
            <TableCell>{fDate(order.created_at)}</TableCell>
            <TableCell>{`${order.total_price}${order.unit}`}</TableCell>
            <TableCell align="right">{order.transaction_id}</TableCell>
            <TableCell align="right"><IconButton onClick={handleExpandClick}>
                <Iconify icon={open ? "eva:arrow-ios-upward-fill" : "eva:arrow-ios-downward-fill"} /></IconButton></TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h5">{`Order details - ${order.id}`}</Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell /> */}
                                    <TableCell>Item</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    JSON.parse(order.data).map((item) => {
                                        return <TableRow key={item.id}>
                                            {/* <TableCell /> */}
                                            <TableCell>{item.product.name}</TableCell>
                                            <TableCell>{`${item.amount}`}</TableCell>
                                            <TableCell>{`${item.product.price}${item.product.unit}`}</TableCell>
                                            <TableCell align="right">{`${item.product.price * item.amount}${item.product.unit}`}</TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </Fragment>
}