import { Card, CardActionArea, CardActions, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Iconify from "./Iconify";

export default function CategoryItem({ category }) {
    const theme = useTheme();
    const navigate = useNavigate();
    return <Card sx={{ width: '100%', height: '100%' }}>
        <CardActionArea onClick={() => category.id ? navigate(`shop?category=${category.id}`) : navigate("shop")}>
            <CardContent>
                <Stack spacing={2}>
                    <Typography variant="h3">{category.title}</Typography>
                </Stack>
            </CardContent>
            <CardActions sx={{ opacity: .6, backgroundColor: theme.palette.divider }}>
                <Stack p={1} direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                    <Typography variant="button">Go to category</Typography>
                    <Iconify icon="eva:arrow-forward-fill" />
                </Stack>
            </CardActions>
        </CardActionArea>
    </Card>
}