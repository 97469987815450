import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import useSnackbar from '../hooks/useSnackbar';

// Styled alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

// Component to display a snackbar
export default function CustomizedSnackbars() {
  // snackbar state
  const { snackbarState, close } = useSnackbar();
  // The state of the snackbar
  const { error, info, success, warning } = snackbarState;

  // Function to close the snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    close();
  };

  // Function to get the severity
  const getSeverity = () => {
    if (error) {
      return 'error';
    }
    if (info) {
      return 'info';
    }
    if (success) {
      return 'success';
    }
    if (warning) {
      return 'warning';
    }
  };

  return (
    <Snackbar
      open={error || info || success || warning}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={getSeverity()} sx={{ width: '100%' }}>
        {snackbarState.text}
      </Alert>
    </Snackbar>
  );
}
