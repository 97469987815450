import { useContext } from 'react';
import { ConfirmContext } from '../contexts/confirm/ConfirmContext';

let resolveCallback;
export default function useConfirm() {
  const [confirmState, confirmDispatch] = useContext(ConfirmContext);

  // Function to close the confirm dialog
  const closeConfirm = () => {
    confirmDispatch({ type: 'close', title: '', text: '' });
  };

  // Function to cancel the confirm dialog
  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };

  // Function to confirm the confirm dialog
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  // Function to show the confirm dialog
  const confirm = (title = 'Er du sikker?', text = "This action can't be undone.") => {
    confirmDispatch({ type: 'show', title, text });
    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  return { confirm, onConfirm, onCancel, confirmState };
}
