export const reducer = (state, action) => {
  switch (action.type) {
    case 'show': {
      return { show: true, title: action.title, text: action.text };
    }
    case 'close': {
      return { show: false, title: action.title, text: action.text };
    }
    default: {
      return false;
    }
  }
};

export const initialState = { show: false, title: 'Er du sikker?', text: 'Denne handling kan ikke fortrydes.' };
