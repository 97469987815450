import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

// props
PrivateRoute.propTypes = {
    children: PropTypes.node.isRequired,
    login: PropTypes.bool,
};

// Component to check if the user is logged in
export default function PrivateRoute({ children, login }) {
    let token = window.localStorage.getItem('access_token');

    // Check if token is in old location
    if (!token) {
        const oldToken = window.localStorage.getItem('finca_app_token');
        if (oldToken) {
            window.localStorage.setItem('access_token', oldToken);
            window.localStorage.removeItem('token');
            token = oldToken;
        }
    }

    if (token && login) {
        return <Navigate to='/app' />;
    }

    if (!token && !login) {
        return <Navigate to='/sign-on' />;
    }
    return children;
}