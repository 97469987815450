import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { initialState, reducer } from './Reducer';

export const CartContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <CartContext.Provider value={[state, dispatch]}>{children}</CartContext.Provider>;
};

// proptypes
CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
