import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { initialState, reducer } from './Reducer';

export const SwitchThemeContext = React.createContext({
  state: initialState,
  dispatch: () => null,
});

export const SwitchThemeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SwitchThemeContext.Provider value={[state, dispatch]}>{children}</SwitchThemeContext.Provider>
  );
};

SwitchThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
