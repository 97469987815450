export const reducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      return action.value;
    }
    default: {
      return state;
    }
  }
};

export const initialState = [];
