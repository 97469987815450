import { Card, CardActions, CardContent, CardMedia, Chip, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { CartContext } from "../contexts/cart/CartContext";
import Iconify from "./Iconify";

export default function ProductItem({ product }) {
    const theme = useTheme();
    const [cart, dispatch] = useContext(CartContext);

    const addToCart = () => {
        dispatch({ type: 'add', key: product.id, value: product });
    }

    const removeFromCart = () => {
        dispatch({ type: 'sub', key: product.id });
    }



    return <Card sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: "space-between", position: 'relative' }}>
        <CardMedia component="img" image={product.image} sx={{ objectFit: 'contain', height: 250, pt: 2 }} title={product.name} />
        <CardContent>
            <Stack spacing={2}>
                <Chip label={`${product.price}${product.unit}`} color="info" sx={{ fontSize: 32, fontWeight: 900, position: 'absolute', left: 10, top: 226, height: 48 }} />
                {/* <Box sx={{ position: 'relative', height: 0 }}>
                </Box> */}
                <Typography sx={{ pt: '0px' }} variant="h6">{`${product.name}`}</Typography>
            </Stack>
        </CardContent>
        <CardActions>
            <Stack direction="row" alignItems="center" justifyContent="space-evenly" sx={{ width: '100%' }} px={2}>
                <IconButton sx={{ width: 64, height: 64, color: theme.palette.text.primary }} onClick={removeFromCart}>
                    <Iconify sx={{ width: '100%', height: '100%' }} icon="eva:minus-circle-fill" />
                </IconButton>
                <Typography variant="h3">{cart.hasOwnProperty(product.id) ? cart[product.id].amount : 0}</Typography>
                <IconButton sx={{ width: 64, height: 64, color: theme.palette.text.primary }} onClick={addToCart}>
                    <Iconify sx={{ width: '100%', height: '100%' }} icon="eva:plus-circle-fill" />
                </IconButton>
            </Stack>
        </CardActions>
    </Card>
}