import { Button, Card, CardContent, Container, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";

export default function LoadingPage() {
    const theme = useTheme();
    const [unexpectedlyLong, setUnexpectedlyLong] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        const timeout = setTimeout(() => {
            setUnexpectedlyLong(true);
        }, 10000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const handleReturnToStart = () => {
        // Perhabs send a webhook or some logging here

        navigate('/app');
    }

    return <Container maxWidth="sm">
        <Stack alignItems="center" width="100%">
            <Card>
                <CardContent>
                    <Stack alignItems="center" spacing={2}>
                        <Typography variant="h2">Loading</Typography>
                        <Typography variant="subtitle2">This should not take long</Typography>
                        <ReactLoading type="balls" color={theme.palette.warning.main} height={100} width={100} />
                    </Stack>
                </CardContent>
            </Card>
            {
                unexpectedlyLong && <Stack sx={{ textAlign: 'center' }} spacing={2} mt={2}>
                    <Typography>Is this taking an unexpected long time?</Typography>
                    <Typography>Try refreshing the page</Typography>
                    <Divider>Or</Divider>
                    <Button onClick={handleReturnToStart} color="error" variant="contained">Return to start</Button>
                </Stack>
            }
        </Stack>
    </Container>
}