import PropTypes from 'prop-types';
// material
import { AppBar, Box, Button, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { CartContext } from '../../contexts/cart/CartContext';
import useConfirm from '../../hooks/useConfirm';
import useSnackbar from '../../hooks/useSnackbar';
//

// ----------------------------------------------------------------------

// The height of the appbar on mobile
const APPBAR_MOBILE = 64;
// The height of the appbar on desktop
const APPBAR_DESKTOP = 64;

// Style for the root element
const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    bottom: 0,
    top: 'auto',
}));

// Style for the toolbar
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------
// props
BottomBar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

// Component to show the navbar
export default function BottomBar({ onOpenSidebar }) {
    // If cart context is empty, don't show the bottom bar
    const [cart, dispatch] = React.useContext(CartContext);
    const theme = useTheme();
    const navigate = useNavigate();
    const { confirm } = useConfirm();
    const { success } = useSnackbar();

    const onClearCart = async () => {
        const result = await confirm("Are you sure you want to clear your cart?");

        if (result) {
            dispatch({ type: 'clear' });
            success("Cart cleared!");
        }
    }

    if (Object.keys(cart).length === 0) {
        return null;
    }

    const totalItems = Object.values(cart).reduce((acc, cur) => acc + cur.amount, 0);
    const totalPrice = Object.values(cart).reduce((acc, cur) => acc + cur.amount * cur.product.price, 0);

    return (
        <RootStyle>
            <ToolbarStyle>
                {/* Show total amount of products */}
                <Typography color={theme.palette.text.primary} variant='h6'>{`${totalItems} Items - ${totalPrice}€`}</Typography>
                <Box sx={{ flexGrow: 1 }} />

                <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover />
          <AccountPopover /> */}
                    <Button startIcon={<Iconify icon="bi:cart-x" />} variant='contained' color="error" onClick={onClearCart}>Clear cart</Button>
                    <Button startIcon={<Iconify icon="bi:cart" />} variant='contained' color="success" onClick={() => navigate("/checkout")}>Checkout</Button>
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
