import { Button, Container, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/Iconify";
import { useAuth } from "../hooks/useAuth";
import { loginValidationSchema } from "../schemas/LoginValidationSchema";

export default function SignOnPage() {
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate();
    const { login } = useAuth();


    const formik = useFormik({
        initialValues: {
            name: '',
            password: ''
        },
        validationSchema: loginValidationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const success = await login(values);
                if (!success) {
                    return;
                }
                navigate('/app', { replace: true });
            } catch (error) {
                console.log(error);
            } finally {
                setSubmitting(false);
            }
        }
    })

    return <Container maxWidth="xl" sx={{ height: '100vh' }}>
        <Stack direction="row" alignItems="center" justifyContent="center" height="100%">
            <Container maxWidth="sm">
                <Stack alignItems="center" spacing={2} component="form" onSubmit={formik.handleSubmit}>
                    <Typography variant="h1">Add device</Typography>
                    <Typography align="center" variant="body1">Enter a name for this and the provided password to validate and verify this device.</Typography>
                    <TextField
                        label='Device name'
                        id='name'
                        name='name'
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        label='Password'
                        id='password'
                        name='password'
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    <Button type="submit" variant="contained" color="warning" fullWidth>SIGN ON</Button>
                    <Typography variant="caption">*Device name and password has to be defined in the admin system.</Typography>
                </Stack>
            </Container>
        </Stack>
    </Container>
}