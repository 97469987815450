import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import useDialog from '../../../hooks/useDialog';

// Component to display an error dialog
export default function ErrorDialog() {
  const { dialogState, onCancel } = useDialog();

  if (!dialogState.errorObject) {
    return <Box />;
  }

  return (
    <Dialog open={dialogState.error}>
      <DialogTitle>{dialogState.errorObject.title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>{dialogState.errorObject.text}</Typography>
          <Typography variant='caption'>{dialogState.errorObject.code}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='error' onClick={onCancel}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
