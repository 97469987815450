import { Navigate, Outlet, useRoutes } from "react-router-dom";
import CheckoutLayout from "./layouts/checkout";
import MainLayout from "./layouts/main";
import SimpleLayout from "./layouts/simple";
import CheckOutPage from "./pages/CheckoutPage";
import NotFound from "./pages/NotFound";
import ProductsPage from "./pages/ProductsPage";
import ShowBalancePage from "./pages/ShowBalance";
import ShowBalanceByNumberPage from "./pages/ShowBalanceByNumberPage";
import SignOnPage from "./pages/SignOnPage";
import StartPage from "./pages/StartPage";
import PrivateRoute from "./services/PrivateRoute";

export default function Router() {
    return useRoutes([
        {
            path: '/app',
            element: (
                <PrivateRoute>
                    <MainLayout />
                </PrivateRoute>
            ),
            children: [
                { path: '', element: <StartPage /> },
                { path: 'shop', element: <ProductsPage /> },
                {
                    path: '*',
                    element: <NotFound />,
                },
            ],
        },
        {
            path: '/shop',
            element: (
                <Navigate to='/app' />
            )
        },
        {
            path: '/checkout',
            element: (
                <PrivateRoute>
                    <CheckoutLayout />
                </PrivateRoute>
            ),
            children: [
                { path: '', element: <CheckOutPage /> },
            ],
        },
        {
            path: '/balance',
            element: (
                <PrivateRoute>
                    <SimpleLayout />
                </PrivateRoute>
            ),
            children: [
                { path: '', element: <ShowBalancePage /> },
                { path: ':id', element: <ShowBalanceByNumberPage /> },
            ],
        },
        {
            path: '/',
            element: <Outlet />,
            children: [
                { path: '/', element: <Navigate to='/sign-on' /> },
                {
                    path: '/sign-on',
                    element: (
                        <PrivateRoute login>
                            <SignOnPage />
                        </PrivateRoute>
                    ),
                },
                {
                    path: '/404',
                    element: <NotFound />,
                },
                {
                    path: '*',
                    element: <Navigate to='/404' />,
                },
            ],
        },
    ]);
}