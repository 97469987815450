// Ignore lint react-hooks/exhaustive-deps for this file
/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, Chip, Container, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Iconify from "../components/Iconify";
import ProductsRow from "../components/balance/ProductsRow";
import useApi from "../services/Api";
import { fDate } from "../services/FormatTime";
import LoadingPage from "./Loading";

export default function ShowBalanceByNumberPage() {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    const [orders, setOrders] = useState(null);
    const { api } = useApi();
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await api.get(`/customer/${id}`);
            if (response.status === 200) {
                setCustomer(response.data.customer);
                setOrders(response.data.orders);
            }
        } catch (error) {
            console.error(error);
            navigate(-1);
        }
    }

    if (!customer) {
        return <LoadingPage />
    }

    if (orders) {
        // Sort orders by created_at
        orders.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
        });
    }

    const latestOrder = orders ? orders[0] : null;

    return <Container>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Chip label={customer.status} color={customer.status === "active" ? 'success' : 'error'} sx={{ textTransform: 'capitalize' }} />
                <Typography variant="body2">Customer number</Typography>
                <Typography variant="h1">{customer.name}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card sx={{ height: '100%' }}>
                    <CardContent sx={{ bgcolor: theme.palette.success.dark, height: '100%' }}>
                        <Stack spacing={2} alignItems="center">
                            <Iconify sx={{ width: 64, height: 64 }} icon="solar:bill-check-linear" />
                            <Typography variant="h2">{`${customer.current_tap}€`}</Typography>
                            <Typography variant="body2">Current balance</Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Card sx={{ height: '100%' }}>
                    <CardContent sx={{ bgcolor: theme.palette.info.dark, height: '100%' }}>
                        <Stack spacing={2} alignItems="center">
                            <Iconify sx={{ width: 64, height: 64 }} icon="solar:clock-circle-linear" />
                            <Typography variant="h3">{latestOrder ? `${fDate(latestOrder.created_at)}` : 'No orders'}</Typography>
                            <Typography variant="body2">Last order placed at</Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2}>
                    <Typography variant="h2">Orders</Typography>
                    <Card>
                        <CardContent>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Order number</TableCell>
                                        <TableCell>Timestamp</TableCell>
                                        <TableCell>Total amount</TableCell>
                                        <TableCell align="right">Transaction number</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        orders ? orders.map((order) => {
                                            return <ProductsRow order={order} />
                                        }) : (
                                            <TableRow>
                                                <TableCell colSpan={5}>No orders</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Stack>
            </Grid>
        </Grid>
    </Container>

}