import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Container, Divider, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutOrder from "../components/CheckoutOrder";
import Iconify from "../components/Iconify";
import Numpad from "../components/numpad";
import { CartContext } from "../contexts/cart/CartContext";
import useDialog from "../hooks/useDialog";
import useSnackbar from "../hooks/useSnackbar";
import useApi from "../services/Api";

export default function CheckOutPage() {
    const [cart, dispatch] = useContext(CartContext);
    const [number, setNumber] = useState(null);
    const theme = useTheme();
    const { error } = useDialog();
    const { api } = useApi();
    const navigate = useNavigate();
    const { success } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const backspaceClick = () => {
        if (number !== null && number.length > 0) {
            if (number.length === 1) {
                setNumber(null)
            } else {
                setNumber(number.slice(0, -1))
            }
        }
    }

    const handleClick = (val) => {
        if (number === null) {
            setNumber(val)
        } else {
            if (number.length >= 3) {
                return
            }
            setNumber(number + val)
        }
    }

    const handleConfirm = async () => {
        if (number === null) {
            error("Invalid number", "Please enter a valid number", "1234")
            return
        }
        setLoading(true);
        // Get all the values from the cart object
        const cartItems = Object.values(cart);

        const result = await api.post("order", {
            customer_name: number,
            data: JSON.stringify(cartItems),
            transaction_id: Date.now().toString()
        });

        if (result.status === 200) {
            // Clear cart
            dispatch({ type: "clear" });
            navigate("/app");
            setLoading(false);
            success("Order placed", "Your order has been placed successfully");
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <Container>
        <Stack spacing={2} alignItems="flex-start" maxWidth="md" m="auto">
            <Typography variant="h2">Checkout</Typography>
            <Stack direction="row" spacing={2} alignItems="center">
                <Iconify sx={{ width: 128, height: 128, color: theme.palette.warning.main }} icon="solar:verified-check-linear" />
                <Stack>
                    <Typography color={theme.palette.warning.main} variant="h3">Review your order</Typography>
                    <Typography variant="body2">Review and verify that your order is correct</Typography>
                </Stack>
            </Stack>
            <Box sx={{ width: '100%' }}>
                <CheckoutOrder />
            </Box>
            <Divider sx={{ width: '100%' }} />

            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={"space-between"} alignItems="flex-start" width="100%" spacing={2}>
                <Stack spacing={2} direction="row" alignItems="center">
                    <Iconify sx={{ width: 128, height: 128, color: theme.palette.success.main }} icon="solar:bill-check-linear" />
                    <Stack>
                        <Typography color={theme.palette.success.main} variant="h3">Complete your order</Typography>
                        <Typography variant="body2">Enter your number to complete your order</Typography>
                    </Stack>
                </Stack>
                <Card sx={{ alignSelf: 'center' }}>
                    <CardContent>
                        <Stack alignItems="center" spacing={2}>
                            <Grid container sx={{
                                width: 300,
                                borderBottom: 1,
                                mb: 2
                            }}
                                direction={"row"}
                                alignItems={"center"}
                            >
                                <Grid item xs={2}></Grid>
                                <Grid item xs={8} sx={{ textAlign: 'center', fontSize: 38 }}>
                                    {
                                        number !== null ? number : <Typography sx={{ fontSize: 'inherit' }} color={theme.palette.text.disabled}>000</Typography>
                                    }
                                </Grid>
                                <Grid item xs={2}><IconButton onClick={backspaceClick}><Iconify icon="eva:backspace-fill" /></IconButton></Grid>
                            </Grid>
                            <Numpad onClick={handleClick} />
                            <LoadingButton loading={loading} onClick={handleConfirm} size="large" color="success" variant="contained">Checkout</LoadingButton>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            {/* <Box sx={{ flexGrow: 1, maxHeight: `calc(100vh - 176px)` }}>
                <Grid container spacing={2} sx={{ height: '100%', maxHeight: `calc(100vh - 176px)` }} direction={{ xs: 'row', sm: 'row-reverse', md: 'column-reverse' }}>
                <Grid item xs={12} sm={12} md={6} sx={{ height: '100%', maxHeight: `calc(100vh - 176px)` }}>
                <Stack height="100%">
                <Typography variant="h2">Checkout</Typography>
                </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ maxHeight: `calc(100vh - 176px)`, overflow: 'auto' }}>
                </Grid>
                </Grid>
            </Box> */}
        </Stack>
    </Container>
}