import { useContext } from 'react';
import { DialogContext } from '../contexts/dialog/DialogContext';

let resolveCallback;
export default function useDialog() {
  const [dialogState, dialogDispatch] = useContext(DialogContext);

  // Function to close the confirm dialog
  const onCancel = () => {
    dialogDispatch({ type: 'close' });
    resolveCallback(false);
  };

  // Function to confirm the confirm dialog
  const onConfirm = () => {
    dialogDispatch({ type: 'close' });
    resolveCallback(true);
  };

  // Function to show error dialog
  const error = (
    title = 'Der er sket en fejl',
    text = 'Der er sket en uventet fejl. Kontakt administratoren eller prøv igen senere.',
    code = '0001',
  ) => {
    dialogDispatch({
      type: 'error',
      errorObject: { title, text, code },
    });
    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  // Function to show info dialog
  const info = (title, text) => {
    dialogDispatch({ type: 'info', infoObject: { title, text } });
  };

  // Function to show confirm dialog
  const confirm = (title = 'Er du sikker?', text = 'Denne handling kan ikke fortrydes.') => {
    dialogDispatch({ type: 'confirm', confirmObject: { title, text } });
    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  return { error, onCancel, info, confirm, onConfirm, dialogState };
}
